import React, { Component } from 'react'
import styled from 'styled-components/macro';

import * as constants from './constants.js'

import * as theme from './Theme.js'


const StyledHeader = styled.div`
  position: fixed;
  display: flex;
  padding: 12px 5% 12px 5%;
  align-items: center;
  background-color: ${theme.COLOR_HEADER_BACKGROUND};
  top: ${props => props.top}px;
  left: 0;
  width: 100%;
  z-index: 100;
`;

const Title = styled.div`
  color: ${theme.COLOR_HEADER_TITLE};
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.4em;
  letter-spacing: 0.4em;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const Menu = styled.div`
  display: flex;
  font-family: 'Poppins', sans-serif;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const MenuEntry = styled.a`
  margin: 0px 20px 0px 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9em;
  color: ${props => props.selected ? theme.COLOR_HEADER_MENU_SELECTED : 'inherit'};
  font-weight: ${props => props.selected ? 600 : 'regular'};

  &:visited {
    color: ${props => props.selected ? theme.COLOR_HEADER_MENU_SELECTED : 'inherit'};
  }

  @media (hover: hover) {
    &:hover {
      color: ${theme.COLOR_HIGHLIGHT};
    }
  }
`;

export default class Header extends Component {
  render() {
    return (
      <StyledHeader top={this.props.top}>
        <Title>IBON CANO</Title>
        <Spacer/>
        <Menu>
          <MenuEntry href={'#' + constants.SECTION_HOME} selected={this.props.section === constants.SECTION_HOME}>
            Home
          </MenuEntry>
          <MenuEntry href={'#' + constants.SECTION_GALLERY} selected={this.props.section === constants.SECTION_GALLERY}>
            Work
          </MenuEntry>
          <MenuEntry href={'#' + constants.SECTION_ABOUT} selected={this.props.section === constants.SECTION_ABOUT}>
            About
          </MenuEntry>
        </Menu>
      </StyledHeader>
    )
  }
}
