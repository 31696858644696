import React, { Component } from 'react'
import styled from 'styled-components/macro'

import * as theme from './Theme.js'


const StyledFooter = styled.div`
  font-size: 0.80em;
  margin: 10px ${theme.GLOBAL_MARGIN}px 15px ${theme.GLOBAL_MARGIN}px;
  text-align: left;
  color: #bfbfbf;

  hr {
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #555555;
    height: 0px;
  }
`;

export default class Footer extends Component {
  render() {
    return (
      <StyledFooter>
        <hr/>
        © 2021 Ibon Cano. All rights reserved. Design and development by Ibon Cano
      </StyledFooter>
    )
  }
}
