import React, { Component, Suspense } from 'react'
import styled from 'styled-components/macro'

import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";

import GalleryPhotoZoomed from './GalleryPhotoZoomed.js'

 
const StyledGalleryPhoto = styled(Link)`
  margin-left: ${props => props.space}px;
  margin-right: ${props => props.space}px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  cursor: pointer;
  user-select: none;

  >img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export default class GalleryPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: false
    }

    this.clickHandler = this.clickHandler.bind(this);
    this.setZoom = this.setZoom.bind(this);
  }

  clickHandler() {
    this.setState((state, props) => {return {zoom: !state.zoom}});
  }

  setZoom(zoom) {
    this.setState({
      zoom: zoom
    });
  }

  render() {
    var output;
    console.log(window.location.pathname)
    return (
      <StyledGalleryPhoto width={this.props.width} height={this.props.height} space={this.props.space} to={String(this.props.photo.id)} onClick={this.clickHandler}>
          <img src={this.props.photo.thumbnail}/>
          <Routes>
            <Route path={String(this.props.photo.id)} element={<GalleryPhotoZoomed photo={this.props.photo} setZoomCallback={this.setZoom}/>} />
          </Routes>
      </StyledGalleryPhoto>
    )
  }
}