// Colors

export const COLOR_BACKGROUND = "#111516";
export const COLOR_TEXT = "#dfdfdf";
export const COLOR_HIGHLIGHT = "white";

// Header
export const COLOR_HEADER_BACKGROUND = "rgba(0, 0, 0, 0.20)";
export const COLOR_HEADER_TITLE = "white";
export const COLOR_HEADER_MENU_SELECTED = "white"

// Layout
export const GLOBAL_MARGIN = 10;
