import React, { Component } from 'react'
import styled from 'styled-components/macro';

import config from './CoverConfig.yaml'

import * as constants from '../constants.js'

import * as theme from '../Theme.js'


const StyledCover = styled.div`
  position: relative;
  color: ${theme.COLOR_TEXT};
  top: 0px;
  left: 0px;
  height: 100vh;
  z-index: 0;
`;

const StyledImg = styled.div`
  display: block;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &::before {
    opacity: ${props => props.pointer === 0 ? 1 : 0};
    content: "";
    position: absolute;
    background-image: url("${props => props.photo1}");
    background-position: center;
    background-size: cover;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 1s;
  }

  &::after {
    opacity: ${props => props.pointer === 1 ? 1 : 0};
    content: "";
    position: absolute;
    background-image: url("${props => props.photo2}");
    background-position: center;
    background-size: cover;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 1s;
  }
`;

export default class Cover extends Component {
  constructor(props) {
    super(props);

    this.photos = config.photos
    this.config = config.config

    this.state = {
      photo1: 0,
      photo2: 1 % this.photos.length,
      pointer: 0,
      updateBuffer: true,

      //DOM state
      width:  0,
      height:  0,
      offsetTop: 0
    }

    this.coverRef = React.createRef();
  }

  componentDidMount(){
    this.timerId = setInterval(() => this.nextPhoto(), (this.config.periodSecs*1000)/2);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidUpdate() {
    var height = this.coverRef.current ? this.coverRef.current.clientHeight : 0;
    var offsetTop = this.coverRef.current ? this.coverRef.current.offsetTop : 0;

    if ((this.props.section !== constants.SECTION_HOME) &&
        (this.props.scrollTop >= offsetTop) &&
        (this.props.scrollTop < offsetTop + height))
      this.props.setSectionCallback(constants.SECTION_HOME);
  }

  nextPhoto() {
    this.setState((state, props) => {
        let s;

        if (state.updateBuffer) {
          let photo1;
          let photo2;
  
          if (state.pointer === 0) {
            photo1 = state.photo1;
            photo2 = (state.photo1 + 1) % this.photos.length;
          }
          else {
            photo1 = (state.photo2 + 1) % this.photos.length;
            photo2 = state.photo2;
          }

          s = {
            photo1: photo1,
            photo2: photo2,
            pointer: state.pointer,
            updateBuffer: false
          };
        }
        else {
          s = {
            photo1: state.photo1,
            photo2: state.photo2,
            pointer: (state.pointer + 1) % 2,
            updateBuffer: true
          };
        }

        return s;
      } 
    );
  }//nextPhoto()

  render() {
    var output;

    if (this.photos && this.photos.length > 0)
      output = (
        <StyledImg photo1={this.photos[this.state.photo1].file} photo2={this.photos[this.state.photo2].file} pointer={this.state.pointer}/>
      )

    return (
      <StyledCover ref={this.coverRef}>
        {output}
      </StyledCover>
    )
  }
}
