import React, { Component } from 'react'
import styled from 'styled-components/macro';
import * as theme from './Theme.js';

import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";

import Cover from './Cover/Cover.js'
import Header from './Header.js'
import Gallery from './Gallery/Gallery.js'
import About from './About.js'

import * as constants from './constants.js'


const StyledApp = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: ${theme.COLOR_TEXT};
  background-color: ${theme.COLOR_BACKGROUND};
  font-family: 'Open Sans';
  font-weight: 400;
`;

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: constants.SECTION_HOME,
      scrollTop: 0,
      headerTop: 0
    }

    this.listenToScroll = this.listenToScroll.bind(this);
    this.setSection = this.setSection.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }

  listenToScroll() {
    this.setState({
      scrollTop: document.documentElement.scrollTop
    });
  }

  setSection(section) {
    this.setState({
      section: section
    });
  }

  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME}>
      <StyledApp>
        <Header section={this.state.section}/>
        <section id={constants.SECTION_HOME}/>
        <Cover scrollTop={this.state.scrollTop} section={this.state.section} setSectionCallback={this.setSection}/>
        <section id={constants.SECTION_GALLERY}/>
        <Gallery scrollTop={this.state.scrollTop} section={this.state.section} setSectionCallback={this.setSection}/>
        <section id={constants.SECTION_ABOUT}/>
        <About scrollTop={this.state.scrollTop} section={this.state.section} setSectionCallback={this.setSection}/>
      </StyledApp>
      </Router>
    )
  }
}
