import React, { Component } from 'react';
import styled from 'styled-components/macro';

import GalleryPhoto from './GalleryPhoto.js'


const StyledGalleryRow = styled.div`
  display: flex;
  margin-top: ${props => props.gap}px;
  margin-bottom: ${props => props.gap}px;
  width: ${props => props.width}px;
  justify-content: space-between;
  align-items: space-between;
`;

export default class GalleryRow extends Component {
  render() {
    var photos = [];

    var height = this.props.photos[0].height;
    var totalWidth = 0;
    var widths = [];
    for (let i in this.props.photos) {
      let p = this.props.photos[i];
      var ratio = p.width / p.height;
      var width = Math.round(height * ratio);
      totalWidth += width;
      widths.push(width);
    }

    var reduceFactor = (this.props.width - (this.props.photos.length * 2 * this.props.gap)) / totalWidth;

    for (let i in this.props.photos) {
      let p = this.props.photos[i];
      photos.push(
        <GalleryPhoto photo={p} width={Math.round(widths[i] * reduceFactor)} height={Math.round(height * reduceFactor)} space={this.props.gap}/>
      );
    }
 

    return (
      <StyledGalleryRow width={this.props.width} gap={this.props.gap}>
        {photos}
      </StyledGalleryRow>
    )
  }
}
