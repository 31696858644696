import React, { Component } from 'react'
import styled from 'styled-components/macro';

import Footer from './Footer.js'

import * as theme from './Theme.js'

import * as constants from './constants.js'


const StyledAbout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: theme.COLOR_TEXT;
  margin: auto;
  padding-top: 100px;
  min-height: 100vh;

  @media only screen and (orientation: landscape) {
    padding-top: 50px;
  }
`;

const Content = styled.div`
  background-image: url("about.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60% top;
  margin-top: 20%;
  margin-left: auto;
  margin-right: auto;
  width: 80vw;
  height: 80vw;

  @media only screen and (orientation: landscape) {
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    height: 500px;
  }

  @media only screen and (max-height: 500px) {
    margin-top: 3%;
    background-position: 60% 50%;
    width: 360px;
    height: 225px;
  }
`;

const Info = styled.div`
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.95) 25%, rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.50), transparent);
  width: 62%;
  height: 100%;
  padding: 15px 15px 15px 15px;
  font-size: 0.75em;
  text-align: left;

  #title {
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: 0.2em;
    margin-bottom: 0.5em;
  }

  .separator {
    margin: 10px 0px 10px 0px;
    height: 1px;
    width: 50px;
    background-color: ${theme.COLOR_TEXT};
  }

  .info-section {
    .info-section-title {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

  @media only screen and (orientation: landscape) {
    width: 330px;
    height: 100%;
    padding: 50px 50px 50px 35px;
    font-size: 0.9em;
    text-align: left;

    #title {
      font-size: 1.5em;
      margin-bottom: 2em;
    }

    .separator {
      margin: 25px 0px 25px 0px;
      height: 1px;
      width: 50px;
      background-color: ${theme.COLOR_TEXT};
    }
  }

  @media only screen and (max-height: 500px) {
    width: 230px;
    height: 100%;
    padding: 10px 10px 10px 10px;
    font-size: 0.70em;
    text-align: left;

    #title {
      font-size: 1.5em;
      margin-bottom: 0.3em;
    }

    .separator {
      margin: 7px 0px 7px 0px;
      height: 1px;
      width: 50px;
      background-color: ${theme.COLOR_TEXT};
    }

    .info-section {
      .info-section-title {
        margin-bottom: 4px;
        font-weight: bold;
      }
    }
`;

const Icon = styled.img`
  display: span;
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  margin-right: 0.5em;
`;


export default class About extends Component {
  
  constructor(props) {
    super(props);
    this.aboutRef = React.createRef();
  }

  componentDidUpdate() {
    var height = this.aboutRef.current ? this.aboutRef.current.clientHeight : 0;
    var offsetTop = this.aboutRef.current ? this.aboutRef.current.offsetTop : 0;

    if ((this.props.section !== constants.SECTION_ABOUT) &&
        (this.props.scrollTop >= offsetTop) &&
        (this.props.scrollTop < offsetTop + height))
      this.props.setSectionCallback(constants.SECTION_ABOUT);
  }

  render() {
    return (
      <StyledAbout ref={this.aboutRef}>
        <Content>
          <Info>
            <div id='title'>About</div>
            <div className="info-section">
              London based computer engineer and amateur photographer.
            </div>
            <div className="separator"/>
            <div className="info-section">
              <div className="info-section-title"><Icon src="email-icon.png"/>Email</div>
              <a href="mailto:ibon.cano.sanz@gmail.com" target="_blank">ibon.cano.sanz@gmail.com</a>
            </div>
            <div className="separator"/>
            <div className="info-section">
              <div className="info-section-title"><Icon src="flickr-icon.png"/>Flickr</div>
              <a href="https://flickr.com/photos/ibontxo" target="_blank">flickr.com/photos/ibontxo</a>
            </div>
            <div className="separator"/>
            <div className="info-section">
              <div className="info-section-title"><Icon src="instagram-icon.png"/>Instagram</div>
              <a href="https://instagram.com/ibicano" target="_blank">ibicano</a>
            </div>
          </Info>
        </Content>
        <Footer/>
      </StyledAbout>
    )
  }
}
