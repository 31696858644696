import React, { Component } from 'react'
import styled from 'styled-components/macro'

import config from './GalleryConfig.yaml'

import GalleryRow from './GalleryRow.js'

import * as constants from '../constants.js'

import * as theme from '../Theme.js'



const StyledGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;
  margin-left: ${props => props.marginLeft}px;
  margin-right: ${props => props.marginRight}px;
  padding-top: 100px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: space-around;
  background-color: transparent;

  @media only screen and (orientation: landscape) {
    padding-top: 50px;
  }
`;

export default class Gallery extends Component {
  DEFAULT_ROW_ELEMENTS = 4;
  MOBILE_ROW_ELEMENTS = 2;
  EXTRA_WIDTH_ROW_ELEMENTS = 6;
  ROW_GAP = 10;
  MARGIN_WIDTH = theme.GLOBAL_MARGIN;
  
  constructor(props) {
    super(props);

    this.photos = config.photos;
    this.state = {
      rowElements: this.DEFAULT_ROW_ELEMENTS
    }

    this.updateState = this.updateState.bind(this);

    window.addEventListener("resize", this.updateState);

    this.galleryRef = React.createRef();
  }

  updateState() {
    let rowElements = this.DEFAULT_ROW_ELEMENTS;

    if (window.matchMedia('(orientation: landscape)').matches) {
      if (window.matchMedia('(min-width: 1800px)').matches) rowElements = this.EXTRA_WIDTH_ROW_ELEMENTS;
      else rowElements = this.DEFAULT_ROW_ELEMENTS;
    }
    else rowElements = this.MOBILE_ROW_ELEMENTS;

    this.setState({
      rowElements: rowElements
    });
  }

  componentDidMount() {
    this.updateState();

    // Caching gallery images
    (async (photos) => {
      for (let p of photos) {
        let img = new Image();
        img.src = p.file;
      }
    })(this.photos);
  }

  componentDidUpdate() {
    var height = this.galleryRef.current ? this.galleryRef.current.clientHeight : 0;
    var offsetTop = this.galleryRef.current ? this.galleryRef.current.offsetTop : 0;

    if ((this.props.section !== constants.SECTION_GALLERY) &&
        (this.props.scrollTop >= offsetTop) &&
        (this.props.scrollTop < offsetTop + height))
      this.props.setSectionCallback(constants.SECTION_GALLERY);
  }

  render() {
    var out = [];

    var rows = this.photos.length / this.state.rowElements;

    var width = this.galleryRef.current ? this.galleryRef.current.clientWidth : 0;

    for (let r = 0; r < rows; r++) {
      out.push(
        <GalleryRow width={width} photos={this.photos.slice(r*this.state.rowElements, r*this.state.rowElements+this.state.rowElements)} gap={this.ROW_GAP}/>
      );
    }

    return (
      <StyledGallery ref={this.galleryRef} marginLeft={theme.GLOBAL_MARGIN} marginRight={theme.GLOBAL_MARGIN}>
        {out}
      </StyledGallery>
    )
  }
}
