import React, { Component } from 'react'
import styled from 'styled-components/macro'

const PHOTO_BORDER = '#505050'

const StyledGalleryPhotoZoomed = styled.div`
  display: flex;
  opacity: ${props => props.hide ? 0 : 1};
  pointer-events:  ${props => props.hide ? "none" : "auto"};
  transition: opacity 0.2s;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.90);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`;

const PhotoContainer = styled.div`
  display: block;
  visibility: inherit;
`;

const PhotoTitle = styled.div`
  display: block;
  visibility: inherit;
  padding: 3px 0px 3px 0px;
  font-size: 0.9em;
`;

const PhotoDescription = styled.div`
  display: block;
  visibility: inherit;
  font-size: 0.9em;
  font-style: italic;
`;

const Photo = styled.img`
  display: inline-block;
  visibility: inherit;
  border: ${PHOTO_BORDER} solid 0px;
  max-width: 90vw;
  max-height: 80vh;
`;

export default class GalleryPhotoZoomed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: true
    }

    this.clickHandler = this.clickHandler.bind(this);

    this.ref = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => this.setState({hide: false}), 100);
    this.ref.current.focus();
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    this.setState({hide: true});
    document.body.style.overflow = 'auto';
  }

  clickHandler() {
    if (!this.props.hide) {
      this.setState({hide: true});
      setTimeout(() => {
        window.history.back();
        this.props.setZoomCallback(false);
      }, 200);
    }
  }

  render() {
    return (
      <StyledGalleryPhotoZoomed hide={this.state.hide} onClick={this.clickHandler} tabIndex="1" onKeyDown={this.clickHandler} ref={this.ref}>
        <PhotoContainer>
          <PhotoTitle>{this.props.photo.title}</PhotoTitle>
          <Photo src={this.props.photo.file} loading="lazy"/>
          <PhotoDescription>{this.props.photo.description}</PhotoDescription>
        </PhotoContainer>
      </StyledGalleryPhotoZoomed>
    )
  }
}
